import { booleanAttribute, ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TypographyDirective } from '@shared/directives/typography.directive';
import { ActionType } from '@shared/types/generic.types';
import { IconComponent, IconName } from '../icon/icon.component';

type BoxVariant = 'contained' | 'outlined';

@Component({
  selector: 'coach-box',
  host: { '[class]': '[variant(), type()]', '[class.shadow]': 'shadow()', '[style.--accent]': 'accent()' },
  imports: [IconComponent, TypographyDirective],
  templateUrl: './box.component.html',
  styleUrl: './box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
  variant = input<BoxVariant>('outlined');
  type = input<ActionType>();
  title = input<string>();
  icon = input<IconName>();
  accent = input<string>();
  shadow = input(false, { transform: booleanAttribute });
}
